<template>
  <v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%">
    <div class=" my-border">  
    <v-card-title class="py-0 my-0 customlightgray">
      <v-row class="ma-0 pa-0 py-2 justify-center text-left">
        <v-col class="pa-0 ma-auto" cols="7" sm="4" md="4" lg="4" order="1" order-md="1">
          <div class="font-weight-medium text-left text--secondary">  
                      <div class="ma-auto pa-auto">
                        <span class="ma-0 pa-1">{{ $t('taskrules') }}</span>
                        <span style=" border-radius: 22px; font-size:0.6em;"
                            class="custom-color-accent pa-1">{{taskRules.length}}
                        </span>
                      </div>
                  </div>
        </v-col>
        <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
          cols="12" 
          sm="12"
          md="4"
          lg="4">  
          <v-text-field
          v-model="search.searchWord"
          class="ma-0 pa-0"
          append-icon="mdi-magnify"
          :label="$t('search')"
          dense  key=""
          outlined
          clearable
          hide-details
          >
          </v-text-field>
        </v-col>
        <v-col class="text-right pa-0 my-2 ma-md-auto" cols="5" sm="8" md="4" lg="4" order="2" order-md="3"> 
          <v-btn class="ma-auto pa-auto custom-color-accent" @click="addTaskRule()" depressed>{{$t("Add Task Rule")}}</v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <!-- Add Task Rules dialog -->
    <v-dialog v-model="dialog" max-width="800">
      <v-card class="elevation-0">
        <v-card-title class="justify-center ma-0 gray">
          <span class="font-weight-light">{{$t(formTitle)}}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="ma-0 pa-0">
          <v-container class="ma-0 elevation-0 pa-0 ma-0">
          <v-form  v-on:submit.prevent ref="form"
              v-model="valid"
              lazy-validation >
              <v-stepper class="px-0 mx-0 elevation-0 customlightgray"
                  v-model="e6"
                  vertical
              >
                <v-stepper-step
                    :complete="e6 > 1"
                    color="#046e8b"
                    step="1"
                >
                  {{  $t('select') }} {{$t('taskActivity')}}
                </v-stepper-step>
                <v-stepper-content step="1">
                  <v-autocomplete
                      v-model="editRule.activity"
                      :items="getLookup('Event')"
                      item-text="text"
                      item-value="value"
                      class="my-1 py-1"
                      outlined
                      dense
                      clearable
                      :label="$t('taskActivity')"
                      :placeholder="$t('select')"
                      :rules="[rules.required]"
                      @change="checkIfExists"
                  ></v-autocomplete>
                  <v-alert v-show="alreadyexists"
                    border="top"
                    color="red lighten-2"
                    dark>
                    {{$t('alreadyexists')}}
                  </v-alert>
                  <v-btn
                      class="custom-color-accent"
                      :disabled="editRule.activity?!editRule.activity:true"
                      @click="e6 = 2"
                  >
                    {{ $t('continue') }}
                  </v-btn>
                </v-stepper-content>
                <v-stepper-step
                    :complete="e6 > 2"
                    color="#046e8b"
                    step="2"
                >
                  {{ $t('select') }} {{ $t('Module') }}
                </v-stepper-step>
                <v-stepper-content step="2">
                  <v-autocomplete
                      v-model="editRule.modules"
                      :items="modules.map(item=>{return {value:item.value,text:this.$t(item.text)}})"
                      item-text="text"
                      item-value="value"
                      class="my-1 py-1"
                      outlined
                      multiple
                      small-chips
                      dense
                      clearable
                      :label="$t('Modules')"
                      :placeholder="$t('Modules')"
                      :rules="[rules.required]"
                  >
                  </v-autocomplete>
                  <v-btn
                      class="custom-color-accent mr-1"
                      @click="e6 = 3"
                      :disabled="editRule.modules?!editRule.modules.length>0:true"
                  >
                    {{ $t('continue') }}
                  </v-btn>
                  <v-btn text @click="goBack(1)">
                    {{ $t('goback') }}
                  </v-btn>
                </v-stepper-content>

                <v-stepper-step
                    :complete="e6 > 3"
                    color="#046e8b"
                    step="3"
                >
                  {{ $t('selectfields') }}
                </v-stepper-step>

                <v-stepper-content step="3">
                  <div v-for="module in editRule.modules" :key="module">
                    <div>
                      <span class="text--secondary">{{$t(modules.filter(m=>m.value==module)[0].text)}}</span>
                      <v-autocomplete   v-if="getAllFields(module).length>0"
                        v-model="editRule.fields"
                        :items="getAllFields(module)"
                        item-text="text"
                        item-value="value"
                        class="my-1 py-1"
                        outlined
                        multiple
                        small-chips
                        dense
                        clearable
                        :label="$t('selectfields')"
                        :placeholder="$t('selectfields')"
                        :rules="[rules.required]"></v-autocomplete>
                        <a class="font-weight-medium text--secondary d-flex mb-4 hlink" v-else @click.stop="openInNew({name:'managefields'})">{{$t('setfieldsinfieldmanager')}}</a>
                    </div>
                  </div>
                  <v-btn
                      class="custom-color-accent mr-1"
                      @click="saveRules()"
                      :disabled="editRule.fields?!editRule.fields.length>0:true"
                  >
                    {{ $t('save') }}
                  </v-btn>
                  <v-btn text @click="goBack(2)">
                    {{ $t('goback') }}
                  </v-btn>
                </v-stepper-content>
              </v-stepper>
          </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- //Add Task Rules dialog -->
    <!-- Dialog delete confirm-->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card class="customoffwhite">
        <v-card-title>{{ $t('delete') }}</v-card-title>
        <v-card-text
        >{{ $t("Are you sure you want to delete this item?")}}</v-card-text
        >
        <v-card-actions>
          <v-btn class="custom-color-accent-text" text @click="dialogDelete = false">{{
              $t("Close")
            }}</v-btn>
          <v-btn class="custom-color-accent-text" text @click="deleteConfig()">{{
              $t("delete")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- //Dialog delete confirm-->
    <v-divider></v-divider>
    <v-data-table :mobile-breakpoint="1081" 
        :headers="headers"
        :items="taskRules"
        class="elevation-0 customoffwhite text--secondary pa-0 ma-0"
        :search="search.searchWord"
        :loading="loading"
        :items-per-page="15"
        :loading-text="$t('Loading')"
        :footer-props="{
                  'items-per-page-text':$t('rowsperpage')
                }"
        sort-by="id"
    >
      <template class="pa-0 ma-0" v-slot:[`item.activity`]="{ item }">{{ getLookupText('Event',item.activity)[0] }}</template>
      <template class="pa-0 ma-0" v-slot:[`item.modules`]="{ item }">
         <span class="d-flex my-1" v-for="module in JSON.parse(item.data).modules" :key="module">
           <v-chip small>{{$t(modules.filter(m=>m.value==module)[0].text)}}</v-chip>
         </span>
      </template>
      <template class="pa-0 ma-0" v-slot:[`item.fields`]="{ item }">
         <div class="d-flex my-1" v-for="module in JSON.parse(item.data).modules" :key="module">
          <span v-for="(field,i) in JSON.parse(item.data).fields" :key="i">
            <v-chip small v-if="getAllFields(module).filter(f=>f.value==field)[0]">{{getAllFields(module).filter(f=>f.value==field)[0].text}}</v-chip>
          </span>
         </div>
      </template>
      <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
        <v-row class="ma-0 pa-0 text-right">
          <v-col cols="12" class="ma-0 pa-0">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on='on' v-bind="attrs" @click="setAction(item, 'Edit')" icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{$t("edit")}}</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on='on' @click="setAction(item, 'Delete')" v-bind="attrs" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{$t("delete")}}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    </div>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import { MODULES } from "@/helpers/exports";
export default {
  props: ["search", "itemsPerPpage"],
  name: "TaskRule",
  data(){
    return {
      e6:1,
      editFlag: false,
      dialogDelete: false,
      alreadyexists: false,
      valid: true,
      modules: MODULES,
      editId: null,
      editRule:{},
      headers: [
        { text: this.$t("activityname"), value: "activity" },
        { text: this.$t("Modules"), value: "modules"},
        { text: this.$t("fields"), value: "fields"},
        { text: this.$t("date"), value: "created_at"},
        { text: '', value: "action", sortable:false}
      ],
      actions: [
        { title: "Edit" },{ title: "delete" }
      ],
      rules: {
        required: value => !!value || this.$t('required')
      },
      dialog:false
    }
  },
  computed: {
    formTitle() {
      return this.editFlag == false ? "Add Rule" : "Edit Rule";
    },
    ...mapState('configuration', {
      configurations: 'configurations'
    }),
    ...mapState('taskrules',{
      taskRules: 'taskrules',
      loading: 'loading'
    })
  },
  methods:{
    checkIfExists(){
      this.alreadyexists=false;
      if(this.editRule.activity && !this.editFlag)
        if(this.taskRules.filter(item=>item.activity==this.editRule.activity).length>0){
          this.editRule.activity = '';
          this.alreadyexists=true;
        }

    },
    getAllFields(module){
      return this.configurations
        .filter(filter => filter.LookUpName == 'FManager' && filter.LookUpFlag == module )
        .map( item => {
          let arr = [];
          arr["text"] = localStorage.getItem("language") === "en"
              ? item.LookUpStringE
              : item.LookUpStringF;
          arr["value"] = item.LookUpOther;
          return arr;
        })
    },
    goBack(step){
      this.e6=step
    },
    getLookup(column) {
      return this.configurations
          .filter(filter => filter.LookUpName === column)
          .map(item => {
            let arr = [];
            arr["text"] =
                localStorage.getItem("language") === "en"
                    ? item.LookUpStringE
                    : item.LookUpStringF;
            arr["value"] = item.LookUpKey;
            return arr;
          });
    },
    getLookupText(column,text) {
      return this.configurations
          .filter(filter => filter.LookUpName == column && filter.LookUpKey == text)
          .map(item => {
            return localStorage.getItem("language") === "en"
                    ? item.LookUpStringE
                    : item.LookUpStringF;
          });
    },

    setAction(item, action) {
      switch (action) {
        case "Edit":
          this.dialog = true;
          this.e6 = 1;
          this.editFlag = true;
          this.editId = item.id;
          this.editRule = JSON.parse(item.data);
          break;
        case "Delete":
          this.deleteItem = item;
          this.dialogDelete = true;
          break;
        default:
          // code block
      }
    },
    openInNew(r){
      let routeData = this.$router.resolve(r);
      window.open(routeData.href, '_blank');
    },
    addTaskRule(){
      this.editFlag = false;
      this.e6 = 1;
      this.editRule = {};
      this.dialog = true;
    },
    saveRules(){
      if(this.$refs.form.validate()){
        let data = {
          activity: this.editRule.activity,
          data: JSON.stringify(this.editRule)
        };
        if(this.editFlag){
          data.id=this.editId;
          this.updateTaskRule(data).then(()=> this.dialog = false ).then(()=>{
            this.$root.$emit("callAlert", {text: this.$t('itemUpdatedAlert'), type: "dark", alert: true });
          })
        }else{
          this.postTaskRule(data).then(()=> this.dialog = false).then(()=>{
            this.$root.$emit("callAlert", {text: this.$t('itemAddedAlert'), type: "success", alert: true });
          })
        }
      }
    },
    async deleteConfig() {
      this.deleteTaskRule(this.deleteItem.id).then(()=>{
        this.dialogDelete = false;
        this.$root.$emit("callAlert", {text: this.$t('itemRemovedAlert'), type: "dark", alert: true });
      })
    },
    ...mapActions('taskrules',['postTaskRule','deleteTaskRule','updateTaskRule']),
  }
}
</script>

<style scoped>
.hlink{
  width: fit-content;
  text-decoration: underline;
}
.hlink:hover{
  color:#046e8b !important;
}
</style>