<template>
  <task-rule-table
      :search="search"
      :itemsPerPpage="itemsPerPpage"
  />
</template>
<script>
import TaskRuleTable from "../components/task/TaskRules"
import { mapActions, mapState } from "vuex";
export default {
  components:{
    'task-rule-table': TaskRuleTable
  },
  async created(){
    await this.retriveTaskRules();
    this.loading = false;
  },
  data(){
    return {
      search: {
        searchWord: ""
      },
      itemsPerPpage: 10
    };
  },
  computed: {
    ...mapState('taskrules', {
      taskrules: "taskrules"
    })
  },

  methods: {
    ...mapActions('taskrules', ['retriveTaskRules'])
  }
}
</script>
